import { TConvertor, TCreateConverter, TDeleteConverter, TFetchConverter } from 'antd/data/types/converter.types';
import {
  EAnimalStatusDB,
  EAnimalStatusUI,
  EAnimalTypes,
  EAnimalTypesUI,
  EAuthorizationStatus,
  EAuthorizationStatusUi,
  EResidenceStatusDB,
  EResidenceStatusUI,
  TAnimalApplication,
  TAnimalApplicationUi,
  TAnimalBasicInfo,
  TAnimalBasicInfoUi,
  TAnimalDB,
  TAnimalDetail,
  TAnimalDetailUi,
  TAnimalResidenceDB,
  TAnimalResidenceUI,
  TAnimalTerminateDB,
  TAnimalTerminateUI,
  TAnimalUI,
  TAnimalUnitDB,
  TAnimalUnitUI,
  TAnimalWeightDB,
  TAnimalWeightUI,
  TDnaInfoDB,
  TDnaInfoUI,
  TInfoDNA,
  TInfoDNAUi,
  TProperty,
  TPropertyDataUi,
  TPropertyDB,
  TResidentProfileDB,
  TResidentProfileUI,
  TUnit,
  TUnitUi,
  TAnimalOwnerInfoUI,
  TAnimalOwnerInfoDB,
  TApplicantAnimalUI,
  TApplicantAnimalDB,
  TDataOverviewUI,
  TDataOverviewDB,
  EDocsOverviewStatusUI,
  EDocsOverviewStatusDB,
  EPictureOverviewStatusDB,
  EPictureOverviewStatusUI,
  EVetRecordOverviewStatusDB,
  EVetRecordOverviewStatusUI,
  TAnimalStatisticDB,
  TAnimalStatisticUI,
  TAnimalsStatisticsUI,
  TAnimalsStatisticsDB,
  TPreventedAnimalDB,
  TPreventedAnimalUI,
  EPreventedAnimalStatusDB,
  EPreventedAnimalStatusUI,
  TChangerStatusRolesDB,
  TChangerStatusRolesUI,
  TDuplicateAnimalUI,
  TDuplicateAnimalDB,
  TAggressiveBehaviorUI,
  TAggressiveBehaviorDB,
  EAnimalCharginStatusDB,
  EAnimalCharginStatusUI,
  TAnimalSpecieDB,
  TAnimalSpecieUI,
  TAnimalColorsUI,
  TAnimalColorsDB,
  TAnimalBreedDB,
  TAnimalBreedUI,
  TCreateAnimalUI,
  TCreateAnimalDB,
  ENotBeingChargedReasonDB,
  ENotBeingChargedReasonUI,
  EAnimalTagsDB,
  EAnimalTagsUI,
  TAddedPetsUI,
  TAddedPetsDB,
  TFollowUpAnimalItemUI,
  EPolicyOverviewStatusUI,
  EPolicyOverviewStatusDB,
  TUpdateAnimalNameUI,
  TUpdateAnimalNameDB,
  TUpdateAnimalSpeciesUI,
  TUpdateAnimalSpeciesDB,
  TUpdateAnimalWeightUI,
  TUpdateAnimalWeightDB,
  TUpdateAnimalBirthDateDB,
  TUpdateAnimalBirthDateUI,
  TUpdateAnimalMicrochipDateDB,
  TUpdateAnimalMicrochipDateUI,
  TUpdateAnimalReproductiveDB,
  TUpdateAnimalReproductiveUI,
  TUpdateAnimalHouseTrainedUI,
  TUpdateAnimalHouseTrainedDB,
  TUpdateAnimalGenderDB,
  TUpdateAnimalGenderUI,
  TUpdateAnimalBreedDB,
  TUpdateAnimalBreedUI,
  TUpdateAnimalAggressiveBehaviorDB,
  TUpdateAnimalAggressiveBehaviorUI,
  TUpdateAnimalTrainedForUI,
  TAnimalInfoPropUI,
  TAnimalInfoPropDB,
  TUpdateAnimalColorsDB,
  TUpdateAnimalColorsUI,
  TUpdateAnimalTrainedForDB,
  TUpdateAnimalProfilePictureUI,
  TUpdateAnimalProfilePictureDB,
  TGender,
  TGenderUI,
  TAnimalVetRecordCreateUi,
  TAnimalVetRecordCreate,
  TVetRecordDocumentsUI,
  TVetRecordDocumentsDB,
  TVaccinationRecordUI,
  TNewVaccinationRecordDB,
  TVetRecordDeleteDB,
  TVetRecordDeleteUI,
  TCreateAnimalResponseUI,
  TCreateAnimalResponseDB,
  TResidentAnimalScreeningUI,
  TResidentAnimalScreeningDB,
  TConditionalMovedInApprovedUI,
  TConditionalMovedInApprovedDB,
  EConversionStatusUI,
  EConversionStatusDB,
  TDownloadAnimalDocumnetsDB,
  TDownloadAnimalDocumnetsUI,
} from 'antd/data/types/animals.types';
import { formattedDate } from 'antd/helpers/utils';
import { b64toBlob } from 'antd/api/sevices/fileServices';
import moment from 'moment';

import {
  applicationStatusConvertor,
  statusInfoWithResourceConverter,
  genderEnumConvertor,
  getFirstLetters,
  policyDataConverter,
  predefinedOptionConverter,
  propertyDataConverter,
  reproductiveConvertor,
  selectOptionConverter,
  statusChangeInfoConverter,
  unitDataConverter,
  verificationStatusConverter,
  yesNoOptionConverter,
} from './general.converters';
import {
  EVetRecordStatus,
  EVetRecordStatusUi,
  TAnimalVetRecord,
  TAnimalVetRecordUi,
  TVetRecordDB,
  TVetRecordTypeDB,
  TVetRecordTypeUI,
  TVetRecordUI,
} from '../types/vetRecord.types';
import { EVetRecordTypeEnumDB, EVetRecordTypeEnumUI, TExpirationDateType, TFileDataType } from '../types/general.types';
import { docPacketStatusConverter, getHPDocPacketsConvertor } from './docPacket.convertors';
import { TSADocPacketGet, TSADocPacketGetUI } from '../types/animalTraining.types';
import { trainerInfoConvertor } from './animalTraining.convertors';
import {
  animalResidencyStatusConverter,
  companyNameConverter,
  residentTagsConvertor,
  tenantResidencyStatusConverter,
} from './residence.converters';
import {
  animalConversionReason,
  animalFollowUpItemConvertor,
  statusChangeHistoryConverter,
} from './application.converters';
import { EPooCompaniesDB, EPooCompaniesUi } from '../types/residence.types';
import { EConversionReasonDB, hudGuidelinesLink, oldHud, vaccinationData } from '../datas/genericData';

export const animalTypeConverter: TConvertor<EAnimalTypesUI, EAnimalTypes> = {
  fromDb: (data) => {
    const dataMapper: { [key in EAnimalTypes]: EAnimalTypesUI } = {
      [EAnimalTypes.ESA]: EAnimalTypesUI.ESA,
      [EAnimalTypes.PET]: EAnimalTypesUI.PET,
      [EAnimalTypes.SA]: EAnimalTypesUI.SA,
    };
    return dataMapper[data];
  },
  toDb: (data) => {
    const dataMapper: { [key in EAnimalTypesUI]: EAnimalTypes } = {
      [EAnimalTypesUI.ESA]: EAnimalTypes.ESA,
      [EAnimalTypesUI.PET]: EAnimalTypes.PET,
      [EAnimalTypesUI.SA]: EAnimalTypes.SA,
    };
    return dataMapper[data];
  },
};

export const conversionStatusConverter: TConvertor<EConversionStatusUI, EConversionStatusDB> = {
  fromDb: (data) => {
    const dataMapper: { [key in EConversionStatusDB]: EConversionStatusUI } = {
      [EConversionStatusDB.PENDING_ESA]: EConversionStatusUI.PENDING_ESA,
      [EConversionStatusDB.PENDING_SA]: EConversionStatusUI.PENDING_SA,
      [EConversionStatusDB.NONE]: EConversionStatusUI.NONE,
    };
    return dataMapper[data];
  },
  toDb: (data) => {
    const dataMapper: { [key in EConversionStatusUI]: EConversionStatusDB } = {
      [EConversionStatusUI.PENDING_ESA]: EConversionStatusDB.PENDING_ESA,
      [EConversionStatusUI.PENDING_SA]: EConversionStatusDB.PENDING_SA,
      [EConversionStatusUI.NONE]: EConversionStatusDB.NONE,
    };
    return dataMapper[data];
  },
};
export const animalStatusConverter: TConvertor<EAnimalStatusUI, EAnimalStatusDB> = {
  fromDb: (data) => {
    const dataMapper: { [key in EAnimalStatusDB]: EAnimalStatusUI } = {
      [EAnimalStatusDB.PENDING]: EAnimalStatusUI.PENDING,
      [EAnimalStatusDB.APPROVED]: EAnimalStatusUI.APPROVED,
      [EAnimalStatusDB.NOT_APPROVED]: EAnimalStatusUI.NOT_APPROVED,
      [EAnimalStatusDB.MOVED_OUT]: EAnimalStatusUI.MOVED_OUT,
      [EAnimalStatusDB.ARCHIVED]: EAnimalStatusUI.ARCHIVED,
      [EAnimalStatusDB.RETRACTED]: EAnimalStatusUI.RETRACTED,
      [EAnimalStatusDB.MOVED_IN]: EAnimalStatusUI.MOVED_IN,
      [EAnimalStatusDB.CONDITIONALLY_APPROVED]: EAnimalStatusUI.CONDITIONALLY_APPROVED,
      [EAnimalStatusDB.CONDITIONALLY_MOVED_IN]: EAnimalStatusUI.CONDITIONALLY_MOVED_IN,
    };
    return dataMapper[data];
  },
  toDb: (data) => {
    const dataMapper: { [key in EAnimalStatusUI]: EAnimalStatusDB } = {
      [EAnimalStatusUI.PENDING]: EAnimalStatusDB.PENDING,
      [EAnimalStatusUI.APPROVED]: EAnimalStatusDB.APPROVED,
      [EAnimalStatusUI.NOT_APPROVED]: EAnimalStatusDB.NOT_APPROVED,
      [EAnimalStatusUI.MOVED_OUT]: EAnimalStatusDB.MOVED_OUT,
      [EAnimalStatusUI.ARCHIVED]: EAnimalStatusDB.ARCHIVED,
      [EAnimalStatusUI.RETRACTED]: EAnimalStatusDB.RETRACTED,
      [EAnimalStatusUI.MOVED_IN]: EAnimalStatusDB.MOVED_IN,
      [EAnimalStatusUI.CONDITIONALLY_MOVED_IN]: EAnimalStatusDB.CONDITIONALLY_MOVED_IN,
      [EAnimalStatusUI.CONDITIONALLY_APPROVED]: EAnimalStatusDB.CONDITIONALLY_APPROVED,
    };

    return dataMapper[data];
  },
};
export const animalTagsConverter: TConvertor<EAnimalTagsUI, EAnimalTagsDB> = {
  fromDb: (data) => {
    const dataMapper: { [key in EAnimalTagsDB]: EAnimalTagsUI } = {
      [EAnimalTagsDB.KNOWN]: EAnimalTagsUI.KNOWN,
      [EAnimalTagsDB.PNC]: EAnimalTagsUI.PNC,
      [EAnimalTagsDB.DISCOVERD]: EAnimalTagsUI.DISCOVERD,
    };
    return dataMapper[data];
  },
  toDb: (data) => {
    const dataMapper: { [key in EAnimalTagsUI]: EAnimalTagsDB } = {
      [EAnimalTagsUI.KNOWN]: EAnimalTagsDB.KNOWN,
      [EAnimalTagsUI.PNC]: EAnimalTagsDB.PNC,
      [EAnimalTagsUI.DISCOVERD]: EAnimalTagsDB.DISCOVERD,
    };

    return dataMapper[data];
  },
};
export const notBeingChargedReasonConverter: TConvertor<ENotBeingChargedReasonUI, ENotBeingChargedReasonDB> = {
  fromDb: (data) => {
    const dataMapper: {
      [key in ENotBeingChargedReasonDB]: ENotBeingChargedReasonUI;
    } = {
      [ENotBeingChargedReasonDB.PET_FEE]: ENotBeingChargedReasonUI.PET_FEE,
      [ENotBeingChargedReasonDB.PET_RENT]: ENotBeingChargedReasonUI.PET_RENT,
    };
    return dataMapper[data];
  },
  toDb: (data) => {
    const dataMapper: {
      [key in ENotBeingChargedReasonUI]: ENotBeingChargedReasonDB;
    } = {
      [ENotBeingChargedReasonUI.PET_FEE]: ENotBeingChargedReasonDB.PET_FEE,
      [ENotBeingChargedReasonUI.PET_RENT]: ENotBeingChargedReasonDB.PET_RENT,
    };

    return dataMapper[data];
  },
};
export const animalResidenceStatusConverter: TConvertor<EResidenceStatusUI, EResidenceStatusDB> = {
  fromDb: (data) => {
    const dataMapper: { [key in EResidenceStatusDB]: EResidenceStatusUI } = {
      [EResidenceStatusDB.PENDING]: EResidenceStatusUI.PENDING,
      [EResidenceStatusDB.APPROVED]: EResidenceStatusUI.APPROVED,
      [EResidenceStatusDB.NOT_APPROVED]: EResidenceStatusUI.NOT_APPROVED,
      [EResidenceStatusDB.MOVED_OUT]: EResidenceStatusUI.MOVED_OUT,
      [EResidenceStatusDB.ARCHIVED]: EResidenceStatusUI.ARCHIVED,
      [EResidenceStatusDB.RETRACTED]: EResidenceStatusUI.RETRACTED,
      [EResidenceStatusDB.FORMER_RESIDENT]: EResidenceStatusUI.FORMER_RESIDENT,
    };
    return dataMapper[data];
  },
  toDb: (data) => {
    const dataMapper: { [key in EResidenceStatusUI]: EResidenceStatusDB } = {
      [EResidenceStatusUI.PENDING]: EResidenceStatusDB.PENDING,
      [EResidenceStatusUI.APPROVED]: EResidenceStatusDB.APPROVED,
      [EResidenceStatusUI.NOT_APPROVED]: EResidenceStatusDB.NOT_APPROVED,
      [EResidenceStatusUI.MOVED_OUT]: EResidenceStatusDB.MOVED_OUT,
      [EResidenceStatusUI.ARCHIVED]: EResidenceStatusDB.ARCHIVED,
      [EResidenceStatusUI.RETRACTED]: EResidenceStatusDB.RETRACTED,
      [EResidenceStatusUI.FORMER_RESIDENT]: EResidenceStatusDB.FORMER_RESIDENT,
    };

    return dataMapper[data];
  },
};

export const animalResidenceProfileConverter: TFetchConverter<TResidentProfileUI, TResidentProfileDB> = {
  fromDb: (data) => {
    return {
      name: data.name,
      residentId: data.residentId,
    };
  },
};

export const animalPropertyConverter: TFetchConverter<TPropertyDataUi, TPropertyDB> = {
  fromDb: (data) => {
    return {
      propertyId: data.propertyId,
      name: data.name,
    };
  },
};

export const animalUnitConverter: TFetchConverter<TAnimalUnitUI, TAnimalUnitDB> = {
  fromDb: (data) => {
    return {
      number: data.number,
      property: animalPropertyConverter.fromDb(data.property),
    };
  },
};
export const dnaCompanyConverter: TConvertor<EPooCompaniesUi, EPooCompaniesDB> = {
  fromDb: (data) => {
    const dataMapper: { [key in EPooCompaniesDB]: EPooCompaniesUi } = {
      [EPooCompaniesDB.MR_DOG_POOP]: EPooCompaniesUi.MR_DOG_POOP,
      [EPooCompaniesDB.OTHER]: EPooCompaniesUi.OTHER,
      [EPooCompaniesDB.POO_PRINTS]: EPooCompaniesUi.POO_PRINTS,
    };
    return dataMapper[data];
  },
  toDb: (data) => {
    const dataMapper: { [key in EPooCompaniesUi]: EPooCompaniesDB } = {
      [EPooCompaniesUi.MR_DOG_POOP]: EPooCompaniesDB.MR_DOG_POOP,
      [EPooCompaniesUi.OTHER]: EPooCompaniesDB.OTHER,
      [EPooCompaniesUi.POO_PRINTS]: EPooCompaniesDB.POO_PRINTS,
    };
    return dataMapper[data];
  },
};

export const dnaInfoConverter: TFetchConverter<TDnaInfoUI, TDnaInfoDB> = {
  fromDb: (data) => {
    return {
      company: {
        label:
          data.company !== EPooCompaniesDB.OTHER ? dnaCompanyConverter.fromDb(data.company) : data.companyCustomName,
        value: data.company,
      },
      dnaNumber: data.dnaNumber || '',
      isCollected: data.isCollected,
    };
  },
};

export const animalResidenceConverter: TFetchConverter<TAnimalResidenceUI, TAnimalResidenceDB> = {
  fromDb: (data) => {
    console.log(tenantResidencyStatusConverter.fromDb(data.tenantResidencyStatus));

    return {
      dnaEnabled: data.dnaEnabled,
      residenceId: data.residenceId,
      leaseToDate: data.leaseToDate ? formattedDate(data.leaseToDate) : null,
      moveInDate: data.moveInDate ? formattedDate(data.moveInDate) : null,
      policyId: data.policyId,
      residentProfile: animalResidenceProfileConverter.fromDb(data.residentProfile),
      status: data.status ? animalResidenceStatusConverter.fromDb(data.status) : null,
      unit: animalUnitConverter.fromDb(data.unit),
      tenantResidencyStatus: tenantResidencyStatusConverter.fromDb(data.tenantResidencyStatus),
    };
  },
};

export const animalWeightConverter: TFetchConverter<TAnimalWeightUI, TAnimalWeightDB> = {
  fromDb: (data) => {
    return {
      unit: data.unit,
      value: data.value,
    };
  },
};

export const animalTypesConvertor: TConvertor<EAnimalTypesUI, EAnimalTypes> = {
  fromDb: (data) => {
    return EAnimalTypesUI[EAnimalTypes[data] as keyof typeof EAnimalTypesUI];
  },
  toDb: (data) => {
    return EAnimalTypes[EAnimalTypesUI[data] as keyof typeof EAnimalTypes];
  },
};
export const statusChangerRoleConvertor: TConvertor<TChangerStatusRolesUI, TChangerStatusRolesDB> = {
  fromDb: (data) => {
    const dataMapper: {
      [key in TChangerStatusRolesDB]: TChangerStatusRolesUI;
    } = {
      [TChangerStatusRolesDB.ADMIN]: TChangerStatusRolesUI.ADMIN,
      [TChangerStatusRolesDB.LANDLORD]: TChangerStatusRolesUI.LANDLORD,
      [TChangerStatusRolesDB.PROPERTY_MANAGER]: TChangerStatusRolesUI.PROPERTY_MANAGER,
    };
    return dataMapper[data];
  },
  toDb: (data) => {
    const dataMapper: {
      [key in TChangerStatusRolesUI]: TChangerStatusRolesDB;
    } = {
      [TChangerStatusRolesUI.ADMIN]: TChangerStatusRolesDB.ADMIN,
      [TChangerStatusRolesUI.LANDLORD]: TChangerStatusRolesDB.LANDLORD,
      [TChangerStatusRolesUI.PROPERTY_MANAGER]: TChangerStatusRolesDB.PROPERTY_MANAGER,
    };
    return dataMapper[data];
  },
};
export const animalCharginStatusConverter: TConvertor<EAnimalCharginStatusUI, EAnimalCharginStatusDB> = {
  fromDb: (data) => {
    const dataMapper: {
      [key in EAnimalCharginStatusDB]: EAnimalCharginStatusUI;
    } = {
      [EAnimalCharginStatusDB.UNSPECIFIED]: EAnimalCharginStatusUI.UNSPECIFIED,
      [EAnimalCharginStatusDB.BEING_CHARGED]: EAnimalCharginStatusUI.BEING_CHARGED,
      [EAnimalCharginStatusDB.GRACE_PERIOD]: EAnimalCharginStatusUI.GRACE_PERIOD,
      [EAnimalCharginStatusDB.NOT_APPLICABLE]: EAnimalCharginStatusUI.NOT_APPLICABLE,
      [EAnimalCharginStatusDB.NOT_BEING_CHARGED]: EAnimalCharginStatusUI.NOT_BEING_CHARGED,
      [EAnimalCharginStatusDB.SHOULD_NOT]: EAnimalCharginStatusUI.SHOULD_NOT,
    };
    return dataMapper[data];
  },
  toDb: (data) => {
    const dataMapper: {
      [key in EAnimalCharginStatusUI]: EAnimalCharginStatusDB;
    } = {
      [EAnimalCharginStatusUI.UNSPECIFIED]: EAnimalCharginStatusDB.UNSPECIFIED,
      [EAnimalCharginStatusUI.BEING_CHARGED]: EAnimalCharginStatusDB.BEING_CHARGED,
      [EAnimalCharginStatusUI.GRACE_PERIOD]: EAnimalCharginStatusDB.GRACE_PERIOD,
      [EAnimalCharginStatusUI.NOT_APPLICABLE]: EAnimalCharginStatusDB.NOT_APPLICABLE,
      [EAnimalCharginStatusUI.NOT_BEING_CHARGED]: EAnimalCharginStatusDB.NOT_BEING_CHARGED,
      [EAnimalCharginStatusUI.SHOULD_NOT]: EAnimalCharginStatusDB.SHOULD_NOT,
    };
    return dataMapper[data];
  },
};

export const animalConverter: TFetchConverter<TAnimalUI, TAnimalDB> = {
  fromDb: (data) => {
    return {
      isCustom: data.isPropertyCustom,
      id: data.id,
      name: data.name,
      animalConversionStatus: conversionStatusConverter.fromDb(data.animalConversionStatus),
      chargingStatusInfo: data.chargingStatusInfo
        ? statusInfoWithResourceConverter.fromDb(data.chargingStatusInfo)
        : null,
      firstLetters: getFirstLetters(data.name),
      profilePicture: data.profilePicture,
      propertyName: data.propertyName,
      unitNumber: data.unitNumber,
      status: animalStatusConverter.fromDb(data.status),
      type: animalTypeConverter.fromDb(data.type),
      animalVerificationStatus: verificationStatusConverter.fromDb(data.animalVerificationStatus),
      birthday: data.birthday ? formattedDate(`${data.birthday}`, 'MM/DD/YYYY') : '',
      deleted: data.deleted,
      dnaFeatureEnabled: data.dnaFeatureEnabled,
      dnaInfo: data.dnaInfo ? dnaInfoConverter.fromDb(data.dnaInfo) : null,
      breed: data.breed ? selectOptionConverter.fromDb(data.breed) : null,
      species: data.species ? selectOptionConverter.fromDb(data.species) : null,
      primaryColor: data.primaryColor ? predefinedOptionConverter.fromDb(data.primaryColor) : null,
      weight: data.weight ? animalWeightConverter.fromDb(data.weight) : null,
      residence: data.residence ? animalResidenceConverter.fromDb(data.residence) : null,
      statusChangeInfo: data.statusChangeInfo ? statusChangeInfoConverter.fromDb(data.statusChangeInfo) : null,
      existsNonApprovedAnimalApplication: data.existsNonApprovedAnimalApplication,
      isApproved: data.existsNonApprovedAnimalApplication ? 'No' : 'Yes',
      submitionDate: data.submissionDate ? formattedDate(data.submissionDate) : null,
      chargingStatus: animalCharginStatusConverter.fromDb(data.chargingStatus),
      notBeingChargedReason: data.notBeingChargedReason
        ? notBeingChargedReasonConverter.fromDb(data.notBeingChargedReason)
        : null,
      tags: (data.tags || []).map((tag) => animalTagsConverter.fromDb(tag)),
      discoveredTagReason: data.discoveredTagReason,
      secondaryColor1: data.secondaryColor1 ? predefinedOptionConverter.fromDb(data.secondaryColor1) : null,
      secondaryColor2: data.secondaryColor2 ? predefinedOptionConverter.fromDb(data.secondaryColor2) : null,
    };
  },
};

export const aggressiveBehaviorConverter: TFetchConverter<TAggressiveBehaviorUI, TAggressiveBehaviorDB> = {
  fromDb: (data) => {
    return {
      acted: data.acted ? 'Yes' : 'No',
      incidentDetails: data.incidentDetails,
    };
  },
};

export const animalInfoPropConverter: TFetchConverter<TAnimalInfoPropUI, TAnimalInfoPropDB> = {
  fromDb: (data) => ({
    value: data.value,
    predefined: data.predefined,
    predefinedId: data.predefinedId,
  }),
};

export const animalBasicInfoConvertor: TFetchConverter<TAnimalBasicInfoUi, TAnimalBasicInfo> = {
  fromDb: (data) => {
    return {
      birthDate: data.birthDate ? formattedDate(data.birthDate, 'MM/DD/YYYY') : null,
      breed: data.breed ? animalInfoPropConverter.fromDb(data.breed) : null,
      gender: genderEnumConvertor.fromDb(data.gender),
      id: data.id,
      isHouseTrained: data.isHouseTrained,
      reproduction: data.isReproductive,
      name: data.name,
      photoGallery: data.photoGallery,
      primaryColor: data.primaryColor ? animalInfoPropConverter.fromDb(data.primaryColor) : null,
      profilePicture: data.profilePicture,
      secondaryColor1: data.secondaryColor1 ? animalInfoPropConverter.fromDb(data.secondaryColor1) : null,
      secondaryColor2: data.secondaryColor2 ? animalInfoPropConverter.fromDb(data.secondaryColor2) : null,
      species: data.species ? animalInfoPropConverter.fromDb(data.species) : null,
      verificationStatus: verificationStatusConverter.fromDb(data.verificationStatus),
      type: animalTypesConvertor.fromDb(data.type),
      trainedFor: data.trainedFor,
      isRequiredBecauseOfDisability: data.isRequiredBecauseOfDisability,
      weight: data.weight ? animalWeightConverter.fromDb(data.weight) : null,
      aggressiveBehavior: data.aggressiveBehavior ? aggressiveBehaviorConverter.fromDb(data.aggressiveBehavior) : null,
      microchip: data.microchip,
      isEditAllowed: data.editAllowed,
    };
  },
};

export const authorizationStatusConverter: TConvertor<EAuthorizationStatusUi, EAuthorizationStatus> = {
  fromDb: (data) => {
    const statuses: {
      [key in EAuthorizationStatus]: EAuthorizationStatusUi;
    } = {
      [EAuthorizationStatus.AUTHORIZED]: EAuthorizationStatusUi.AUTHORIZED,
    };
    return statuses[data];
  },
  toDb: (data) => {
    const statuses: {
      [key in EAuthorizationStatusUi]: EAuthorizationStatus;
    } = {
      [EAuthorizationStatusUi.AUTHORIZED]: EAuthorizationStatus.AUTHORIZED,
    };
    return statuses[data];
  },
};

export const propertyConvertor: TFetchConverter<TProperty, TPropertyDataUi> = {
  fromDb: (data) => {
    return {
      propertyId: data.propertyId,
      name: data.name,
    };
  },
};

export const unitConvertor: TFetchConverter<TUnit, TUnitUi> = {
  fromDb: (data) => {
    return {
      number: data.number,
      unitId: data.unitId,
      property: propertyConvertor.fromDb(data.property),
    };
  },
};

export const animalOwnerConvertor: TFetchConverter<TAnimalOwnerInfoUI, TAnimalOwnerInfoDB> = {
  fromDb: (data) => {
    return {
      id: data.id,
      email: data.email,
      moveInDate: data.moveInDate ? formattedDate(data.moveInDate) : null,
      leaseToDate: data.leaseToDate ? formattedDate(data.leaseToDate) : null,
      name: data.name,
      phoneNumber: data.phoneNumber,
      policy: policyDataConverter.fromDb({
        policyId: data.policyId,
        policyName: data.policyName,
        policyState: data.policyState,
        dateSigned: data.dateSigned,
        isSigned: data.isSigned,
        signedBy: data.signedBy,
      }),
      profilePicture: data.profilePicture,
      property: propertyDataConverter.fromDb({
        propertyId: data.propertyId,
        propertyName: data.propertyName,
        propertyCustom: data.isPropertyCustom,
      }),
      residencyStatus: tenantResidencyStatusConverter.fromDb(data.status),
      unit: unitDataConverter.fromDb({
        unitId: data.unitId,
        unitName: data.unitName,
      }),
      residentId: data.residentId,
      tags: (data.tags || []).map((tag) => residentTagsConvertor.fromDb(tag)),
      isPropertyCustom: data.isPropertyCustom,
    };
  },
};

export const animalApplicationConvertor: TFetchConverter<TAnimalApplicationUi, TAnimalApplication> = {
  fromDb: (data) => {
    return {
      applicationStatus: applicationStatusConvertor.fromDb(data.applicationStatus),
      id: data.id,
      leaseStartDate: formattedDate(data.leaseStartDate),
      leaseToDate: data.leaseToDate ? formattedDate(data.leaseToDate) : null,
      policyId: data.policyId,
      propertyManagerName: data.propertyManagerName,
      propertyName: data.propertyName,
      unitNumber: data.unitNumber,
    };
  },
};

export const dnaInfoConvertor: TFetchConverter<TInfoDNAUi, TInfoDNA> = {
  fromDb: (data) => {
    return {
      isDnaRequired: data.isDnaRequired,
      isDnaCollected: data.isDnaCollected,
      dnaNumber: data.dnaNumber,
      dnaCompany: data.dnaCompany ? companyNameConverter.fromDb(data.dnaCompany) : null,
    };
  },
};

export const vetRecordStatusConverter: TConvertor<EVetRecordStatusUi, EVetRecordStatus> = {
  fromDb: (data) => {
    const statuses: {
      [key in EVetRecordStatus]: EVetRecordStatusUi;
    } = {
      [EVetRecordStatus.NONE]: EVetRecordStatusUi.NONE,
      [EVetRecordStatus.VERIFIED]: EVetRecordStatusUi.VERIFIED,
    };
    return statuses[data];
  },
  toDb: (data) => {
    const statuses: {
      [key in EVetRecordStatusUi]: EVetRecordStatus;
    } = {
      [EVetRecordStatusUi.NONE]: EVetRecordStatus.NONE,
      [EVetRecordStatusUi.VERIFIED]: EVetRecordStatus.VERIFIED,
    };
    return statuses[data];
  },
};

export const animalVetRecordExpirationConvertor: TFetchConverter<TExpirationDateType, TExpirationDateType> = {
  fromDb: (data) => {
    return {
      date: data.date,
      expirable: data.expirable,
      expired: data.expired,
    };
  },
};

export const documentFilesConvertor: TFetchConverter<TFileDataType, TFileDataType> = {
  fromDb: (data) => {
    return {
      url: data.url,
      name: data.name,
      documentFileId: data.documentFileId,
      createdDate: data.createdDate,
      createdBy: data.createdBy,
    };
  },
};
export const vetRecordTypeConverter: TFetchConverter<TVetRecordTypeUI, TVetRecordTypeDB> = {
  fromDb(data) {
    return {
      predefined: data.predefined,
      value: EVetRecordTypeEnumUI[data.value as keyof typeof EVetRecordTypeEnumUI] || data.value,
    };
  },
};

export const vetRecordsDataConverter: TFetchConverter<TVetRecordUI, TVetRecordDB> = {
  fromDb(data) {
    return {
      id: data.id,
      type: vetRecordTypeConverter.fromDb(data.type),
      expiration: data.expiration ? animalVetRecordExpirationConvertor.fromDb(data.expiration) : null,
      isExpired: data.isExpired,
    };
  },
};

export const animalVetRecordConvertor: TFetchConverter<TAnimalVetRecordUi, TAnimalVetRecord> = {
  fromDb: (data) => {
    return {
      records: (data.records || []).map((record) => vetRecordsDataConverter.fromDb(record)),
      id: data.id,
      documentFiles: (data.documentFiles || []).map((item) => documentFilesConvertor.fromDb(item)),
      status: vetRecordStatusConverter.fromDb(data.status),
      locked: data.locked,
    };
  },
};

export const applicationSADocPacketConvertor: TFetchConverter<TSADocPacketGetUI, TSADocPacketGet> = {
  fromDb: (data) => {
    return {
      id: data.id,
      saLetters: (data.saLetters || []).map((item) => documentFilesConvertor.fromDb(item)),
      status: docPacketStatusConverter.fromDb(data.status),
      trainedFor: data.trainedFor,
      trainerInfo: trainerInfoConvertor.fromDb(data.trainerInfo),
      startTime: data.trainingStartDate ? formattedDate(data.trainingStartDate) : '',
      endTime: data.trainingEndDate ? formattedDate(data.trainingEndDate) : '',
      canEdit: data.modifiable,
    };
  },
};

export const docsOverviewStatusConverter: TConvertor<EDocsOverviewStatusUI, EDocsOverviewStatusDB> = {
  fromDb: (data) => {
    const dataMapper: {
      [key in EDocsOverviewStatusDB]: EDocsOverviewStatusUI;
    } = {
      [EDocsOverviewStatusDB.HAS_UNVERIFIED]: EDocsOverviewStatusUI.HAS_UNVERIFIED,
      [EDocsOverviewStatusDB.HAS_VERIFIED]: EDocsOverviewStatusUI.HAS_VERIFIED,
      [EDocsOverviewStatusDB.MISSING]: EDocsOverviewStatusUI.MISSING,
    };
    return dataMapper[data];
  },
  toDb: (data) => {
    const dataMapper: {
      [key in EDocsOverviewStatusUI]: EDocsOverviewStatusDB;
    } = {
      [EDocsOverviewStatusUI.HAS_UNVERIFIED]: EDocsOverviewStatusDB.HAS_UNVERIFIED,
      [EDocsOverviewStatusUI.HAS_VERIFIED]: EDocsOverviewStatusDB.HAS_VERIFIED,
      [EDocsOverviewStatusUI.MISSING]: EDocsOverviewStatusDB.MISSING,
    };

    return dataMapper[data];
  },
};

export const pictureOverviewStatusConverter: TConvertor<EPictureOverviewStatusUI, EPictureOverviewStatusDB> = {
  fromDb: (data) => {
    const dataMapper: {
      [key in EPictureOverviewStatusDB]: EPictureOverviewStatusUI;
    } = {
      [EPictureOverviewStatusDB.MISSING]: EPictureOverviewStatusUI.MISSING,
      [EPictureOverviewStatusDB.SPECIFIED]: EPictureOverviewStatusUI.SPECIFIED,
      [EPictureOverviewStatusDB.NOT_REQUIRED]: EPictureOverviewStatusUI.NOT_REQUIRED,
    };
    return dataMapper[data];
  },
  toDb: (data) => {
    const dataMapper: {
      [key in EPictureOverviewStatusUI]: EPictureOverviewStatusDB;
    } = {
      [EPictureOverviewStatusUI.MISSING]: EPictureOverviewStatusDB.MISSING,
      [EPictureOverviewStatusUI.SPECIFIED]: EPictureOverviewStatusDB.SPECIFIED,
      [EPictureOverviewStatusUI.NOT_REQUIRED]: EPictureOverviewStatusDB.NOT_REQUIRED,
    };

    return dataMapper[data];
  },
};

export const policyOverviewStatusConverter: TConvertor<EPolicyOverviewStatusUI, EPolicyOverviewStatusDB> = {
  fromDb: (data) => {
    const dataMapper: {
      [key in EPolicyOverviewStatusDB]: EPolicyOverviewStatusUI;
    } = {
      [EPolicyOverviewStatusDB.MISSING]: EPolicyOverviewStatusUI.MISSING,
    };
    return dataMapper[data];
  },
  toDb: (data) => {
    const dataMapper: {
      [key in EPolicyOverviewStatusUI]: EPolicyOverviewStatusDB;
    } = {
      [EPolicyOverviewStatusUI.MISSING]: EPolicyOverviewStatusDB.MISSING,
    };

    return dataMapper[data];
  },
};

export const vetRecordOverviewConverter: TConvertor<EVetRecordOverviewStatusUI, EVetRecordOverviewStatusDB> = {
  fromDb: (data) => {
    const dataMapper: {
      [key in EVetRecordOverviewStatusDB]: EVetRecordOverviewStatusUI;
    } = {
      [EVetRecordOverviewStatusDB.HAS_UNVERIFIED]: EVetRecordOverviewStatusUI.HAS_UNVERIFIED,
      [EVetRecordOverviewStatusDB.HAS_VERIFIED]: EVetRecordOverviewStatusUI.HAS_VERIFIED,
      [EVetRecordOverviewStatusDB.MISSING]: EVetRecordOverviewStatusUI.MISSING,
      [EVetRecordOverviewStatusDB.NOT_REQUIRED]: EVetRecordOverviewStatusUI.NOT_REQUIRED,
    };
    return dataMapper[data];
  },
  toDb: (data) => {
    const dataMapper: {
      [key in EVetRecordOverviewStatusUI]: EVetRecordOverviewStatusDB;
    } = {
      [EVetRecordOverviewStatusUI.HAS_UNVERIFIED]: EVetRecordOverviewStatusDB.HAS_UNVERIFIED,
      [EVetRecordOverviewStatusUI.HAS_VERIFIED]: EVetRecordOverviewStatusDB.HAS_VERIFIED,
      [EVetRecordOverviewStatusUI.MISSING]: EVetRecordOverviewStatusDB.MISSING,
      [EVetRecordOverviewStatusUI.NOT_REQUIRED]: EVetRecordOverviewStatusDB.NOT_REQUIRED,
    };

    return dataMapper[data];
  },
};

export const dataOverviewConvertor: TFetchConverter<TDataOverviewUI, TDataOverviewDB> = {
  fromDb: (data) => {
    return {
      curriculum: data.curriculum,
      profilePicture: pictureOverviewStatusConverter.fromDb(data.profilePicture),
      vetRecord: vetRecordOverviewConverter.fromDb(data.vetRecord),
      isPolicySigned: policyOverviewStatusConverter.fromDb(data.isPolicySigned),
      ...(data.hpDocs && {
        hpDocs: docsOverviewStatusConverter.fromDb(data.hpDocs),
      }),
      ...(data.trainerDocs && {
        trainerDocs: docsOverviewStatusConverter.fromDb(data.trainerDocs),
      }),
    };
  },
};

export const getAnimalConverter: TFetchConverter<TAnimalDetailUi, TAnimalDetail> = {
  fromDb: (data) => {
    const isPredefined = Object.values(EConversionReasonDB).includes(data.toPetConvertedReason as EConversionReasonDB);
    return {
      isScreeningEnabled: data.isScreeningEnabled,
      hasObservableDisability: data.hasObservableDisability,
      animalMoveInDate: formattedDate(data.animalMoveInDate),
      animal: animalBasicInfoConvertor.fromDb(data.animal),
      applications: (data.applications || []).map((item) => animalApplicationConvertor.fromDb(item)),
      dnaInfo: dnaInfoConvertor.fromDb(data.dnaInfo),
      hpDocPackets: (data.hpDocPackets || []).map((packet) => ({
        ...getHPDocPacketsConvertor.fromDb(packet),
      })),
      ownerInfo: animalOwnerConvertor.fromDb(data.ownerInfo),
      saDocuments: (data.saDocuments || []).map((item) => applicationSADocPacketConvertor.fromDb(item)),
      residencyStatus: animalResidencyStatusConverter.fromDb(data.residencyStatus),
      residencyStatusChangeInfo: data.residencyStatusChangeInfo
        ? statusChangeInfoConverter.fromDb(data.residencyStatusChangeInfo)
        : null,
      isToPetConverted: data.isToPetConverted,
      vetRecords: (data.vetRecords || []).map((item) => animalVetRecordConvertor.fromDb(item)),
      dataOverview: dataOverviewConvertor.fromDb({
        ...data.dataOverview,
        ...(!data.ownerInfo.isSigned && {
          isPolicySigned: EPolicyOverviewStatusDB.MISSING,
        }),
      }),
      statusChangeHistory: data.statusChangeHistory.map((item) => statusChangeHistoryConverter.fromDb(item)).reverse(),
      policyContent: data.policyContent ? b64toBlob(data.policyContent, 'text/html') : null,
      canRevokeMoveIn: data.canRevokeMoveIn,
      pendingAnimalApplicationId: data.animalApplicationId,
      chargingStatus: animalCharginStatusConverter.fromDb(data.chargingStatus),
      chargingStatusInfo: data.chargingStatusInfo
        ? statusInfoWithResourceConverter.fromDb(data.chargingStatusInfo)
        : null,
      notBeingChargedReason: data.notBeingChargedReason
        ? notBeingChargedReasonConverter.fromDb(data.notBeingChargedReason)
        : null,
      tags: (data.tags || []).map((tag) => animalTagsConverter.fromDb(tag)),
      followUpItems: (data.followUpItems || []).map((item) => animalFollowUpItemConvertor.fromDb(item)),
      discoveredTagReason: data.discoveredTagReason,
      statusUpdateLocked: data.statusUpdateLocked,
      toPetConvertedReason: isPredefined
        ? animalConversionReason.fromDb(data.toPetConvertedReason as EConversionReasonDB)
        : data.toPetConvertedReason,
      missingNameReason: data.missingNameReason,
      missingTypeReason: data.missingTypeReason,
      missingMoveInDateReason: data.missingMoveInDateReason,
    };
  },
};

export const getAnimalStatisticsItemConverter: TFetchConverter<TAnimalStatisticUI, TAnimalStatisticDB> = {
  fromDb: (data) => {
    return {
      fromDate: formattedDate(data.fromDate),
      totalCount: data.totalCount,
    };
  },
};

export const getAnimalStatisticsConverter: TFetchConverter<TAnimalsStatisticsUI, TAnimalsStatisticsDB> = {
  fromDb: (data) => {
    return {
      addedPets: data.addedPets && getAnimalStatisticsItemConverter.fromDb(data.addedPets),
      preventedAnimals: data.preventedAnimals && getAnimalStatisticsItemConverter.fromDb(data.preventedAnimals),
    };
  },
};

export const animalTerminateConverter: TCreateConverter<TAnimalTerminateUI, TAnimalTerminateDB> = {
  toDb: (data) => {
    return {
      residenceId: data.residenceId,
      animalResidencyId: data.animalResidencyId,
      reason: data.reason,
    };
  },
};

export const applicantAnimalConverter: TFetchConverter<TApplicantAnimalUI, TApplicantAnimalDB> = {
  fromDb: (data) => {
    return {
      id: data.id,
      type: animalTypeConverter.fromDb(data.type),
      applicationStatus: applicationStatusConvertor.fromDb(data.applicationStatus),
      statusChangerRole: data.statusChangerRole ? statusChangerRoleConvertor.fromDb(data.statusChangerRole) : null,
      statusChangerName: data.statusChangerName,
      rejectReason: data.rejectReason,
      moveInDate: data.moveInDate ? formattedDate(data.moveInDate) : null,
      submittedDate: data.submittedDate ? formattedDate(data.submittedDate) : null,
      residentName: data.residentName,
      petName: data.petName,
      propertyName: data.propertyName,
      unitName: data.unitName,
      animalVerificationStatus: verificationStatusConverter.fromDb(data.animalVerificationStatus),
      statusChangeInfo: data.statusChangeInfo ? statusChangeInfoConverter.fromDb(data.statusChangeInfo) : null,
      animalProfilePicture: data.animalProfilePicture,
    };
  },
};

export const addedPetsConverter: TFetchConverter<TAddedPetsUI, TAddedPetsDB> = {
  fromDb: (data) => {
    return {
      isCustom: data.isPropertyCustom,
      id: data.id,
      name: data.name,
      animalConversionStatus: conversionStatusConverter.fromDb(data.animalConversionStatus),
      profilePicture: data.profilePicture,
      propertyName: data.propertyName,
      unitNumber: data.unitNumber,
      birthday: data.birthday ? formattedDate(`${data.birthday}`, 'MM/DD/YYYY') : '',
      species: data.species ? selectOptionConverter.fromDb(data.species) : null,
      breed: data.breed ? selectOptionConverter.fromDb(data.breed) : null,
      weight: data.weight ? animalWeightConverter.fromDb(data.weight) : null,
      conversionDate: formattedDate(`${data.conversionDate}`, 'MM/DD/YYYY'),
      primaryColor: data.primaryColor ? predefinedOptionConverter.fromDb(data.primaryColor) : null,
      residence: data.residence ? animalResidenceConverter.fromDb(data.residence) : null,
      status: animalStatusConverter.fromDb(data.status),
      statusChangeInfo: data.statusChangeInfo ? statusChangeInfoConverter.fromDb(data.statusChangeInfo) : null,
      firstLetters: getFirstLetters(data.name),
      notBeingChargedReason: data.notBeingChargedReason
        ? notBeingChargedReasonConverter.fromDb(data.notBeingChargedReason)
        : null,
      chargingStatus: animalCharginStatusConverter.fromDb(data.chargingStatus),
      tags: (data.tags || []).map((tag) => animalTagsConverter.fromDb(tag)),
      isToPetConverted: data.isToPetConverted,
      animalVerificationStatus: verificationStatusConverter.fromDb(data.animalVerificationStatus),
      discoveredTagReason: data.discoveredTagReason,
      chargingStatusInfo: data.chargingStatusInfo
        ? statusInfoWithResourceConverter.fromDb(data.chargingStatusInfo)
        : null,
    };
  },
};

export const preventedAnimalStatusConverter: TConvertor<EPreventedAnimalStatusUI, EPreventedAnimalStatusDB> = {
  fromDb: (data) => {
    const dataMapper: {
      [key in EPreventedAnimalStatusDB]: EPreventedAnimalStatusUI;
    } = {
      [EPreventedAnimalStatusDB.DENIED]: EPreventedAnimalStatusUI.DENIED,
      [EPreventedAnimalStatusDB.NONE]: EPreventedAnimalStatusUI.NONE,
    };
    return dataMapper[data];
  },
  toDb: (data) => {
    const dataMapper: {
      [key in EPreventedAnimalStatusUI]: EPreventedAnimalStatusDB;
    } = {
      [EPreventedAnimalStatusUI.DENIED]: EPreventedAnimalStatusDB.DENIED,
      [EPreventedAnimalStatusUI.NONE]: EPreventedAnimalStatusDB.NONE,
    };

    return dataMapper[data];
  },
};

export const preventedAnimalConverter: TFetchConverter<TPreventedAnimalUI, TPreventedAnimalDB> = {
  fromDb: (data) => {
    return {
      propertyCustom: data.isPropertyCustom,
      animalId: data.animalId,
      animalBreed: data.animalBreed ? selectOptionConverter.fromDb(data.animalBreed) : null,
      animalCreationTime: formattedDate(data.animalCreationTime),
      animalName: data.animalName,
      animalPrimaryColor: data.animalPrimaryColor ? predefinedOptionConverter.fromDb(data.animalPrimaryColor) : null,
      animalProfilePicture: data.animalProfilePicture,
      animalSpecie: data.animalSpecie ? selectOptionConverter.fromDb(data.animalSpecie) : null,
      animalType: animalTypesConvertor.fromDb(data.animalType),
      animalVerificationStatus: verificationStatusConverter.fromDb(data.animalVerificationStatus),
      applicationRejectReason: data.applicationRejectReason
        ? data.applicationRejectReason.replace(hudGuidelinesLink, 'document').replace(oldHud, 'document')
        : undefined,
      isAnimalToPetConverted: data.isAnimalToPetConverted,
      propertyName: data.propertyName,
      status: preventedAnimalStatusConverter.fromDb(data.status),
      tenantName: data.tenantName,
      unitNumber: data.unitNumber,
      firstLetters: getFirstLetters(data.animalName),
      id: data.id,
    };
  },
};

export const duplicateAnimalConverter: TFetchConverter<TDuplicateAnimalUI, TDuplicateAnimalDB> = {
  fromDb: (data) => {
    return {
      animalId: data.animalId,
      animalName: data.animalName,
      animalType: animalTypesConvertor.fromDb(data.animalType),
      ownerName: data.ownerName,
      ownerUsername: data.ownerUsername,
      status: applicationStatusConvertor.fromDb(data.status),
    };
  },
};

export const animalSpecieConvertor: TConvertor<TAnimalSpecieUI, TAnimalSpecieDB> = {
  fromDb: (data) => {
    return {
      value: data.id || '',
      label: data.value || '',
    };
  },
  toDb: (data) => {
    return {
      ...(data.label === data.value ? { value: data.label } : { id: data.value }),
    };
  },
};

export const animalColorConvertor: TConvertor<TAnimalColorsUI, TAnimalColorsDB> = {
  fromDb: (data) => {
    return {
      value: data.id || '',
      label: data.value || '',
      __isNew__: !!data.id,
    };
  },
  toDb: (data) => {
    return {
      id: !data.__isNew__ ? data.value : null,
      value: data.label,
    };
  },
};

export const animalBreedConvertor: TConvertor<TAnimalBreedUI, TAnimalBreedDB> = {
  fromDb: (data) => {
    return {
      value: data.id || '',
      label: data.value || '',
    };
  },
  toDb: (data) => {
    return {
      ...(data.label === data.value ? { value: data.label } : { id: data.value }),
    };
  },
};

export const genderConvertor: TCreateConverter<TGenderUI, TGender> = {
  toDb: (data) => ({
    type: genderEnumConvertor.toDb(data.gender),
    isReproductive: yesNoOptionConverter.toDb(data.neutered),
  }),
};

export const createAnimalConvertor: TCreateConverter<TCreateAnimalUI, TCreateAnimalDB> = {
  toDb: (data) => {
    return {
      birthDate: data.birthDate ? formattedDate(data.birthDate, 'YYYY-MM-DD') : null,
      breed: data.breed && animalBreedConvertor.toDb(data.breed),
      name: data.name || null,
      primaryColor: data.primaryColor && animalColorConvertor.toDb(data.primaryColor),
      residencyId: data.residencyId.value,
      species: data.species && animalSpecieConvertor.toDb(data.species),
      weight: data.weight || null,
      status: animalResidencyStatusConverter.toDb(data.status),
      moveInDate: data.moveInDate ? formattedDate(data.moveInDate, 'YYYY-MM-DD') : null,
      animalType: animalTypesConvertor.toDb(data.animalType.value),
      secondaryColor1: data.secondaryColor1?.value || null,
      secondaryColor2: data.secondaryColor2?.value || null,
      profilePicture: data.profilePicture ? data.profilePicture.url : null,
      gender: data.gender ? genderConvertor.toDb({ gender: data.gender, neutered: data.neutered }) : null,
      houseTrained: yesNoOptionConverter.toDb(data.houseTrained),

      ...(data.animalType.value === EAnimalTypesUI.SA && {
        trainedFor: data.trainedFor,
      }),
      aggressiveBehavior: {
        acted: yesNoOptionConverter.toDb(data.isAggressive),
        incidentDetails: data.aggressionInfo,
      },
      microchip: data.microchip,
      needToScreen: data.needToScreen,
    };
  },
};

export const followUpAnimalConvertor: TCreateConverter<TFollowUpAnimalItemUI, TFollowUpAnimalItemUI> = {
  toDb: (data) => {
    return {
      animalId: data.animalId,
      description: data.description,
    };
  },
};

export const createAnimalResponseConverter: TFetchConverter<TCreateAnimalResponseUI, TCreateAnimalResponseDB> = {
  fromDb: (data) => {
    return {
      isMovedIn: data.movedIn,
      id: data.animalResidencyId || data.applicationId || '',
    };
  },
};

export const updateAnimalNameConvertor: TCreateConverter<TUpdateAnimalNameUI, TUpdateAnimalNameDB> = {
  toDb: (data) => {
    return {
      animalId: data.id,
      name: data.value,
      tenantResidencyId: data.tenantResidencyId,
    };
  },
};

export const updateAnimalSpeciesConvertor: TCreateConverter<TUpdateAnimalSpeciesUI, TUpdateAnimalSpeciesDB> = {
  toDb: (data) => {
    return {
      animalId: data.id,
      animalSpecies: data.animalSpecies.__isNew__
        ? { value: data.animalSpecies.value }
        : { id: data.animalSpecies.value },
      tenantResidencyId: data.tenantResidencyId,
    };
  },
};

export const updateAnimalWeightConvertor: TCreateConverter<TUpdateAnimalWeightUI, TUpdateAnimalWeightDB> = {
  toDb: (data) => {
    return {
      animalId: data.id,
      unit: 'LBS',
      weight: data.value,
      tenantResidencyId: data.tenantResidencyId,
    };
  },
};

export const updateAnimalBirthDateConvertor: TCreateConverter<TUpdateAnimalBirthDateUI, TUpdateAnimalBirthDateDB> = {
  toDb: (data) => {
    return {
      animalId: data.id,
      birthDate: formattedDate(data.value, 'YYYY-MM-DD'),
      tenantResidencyId: data.tenantResidencyId,
    };
  },
};

export const updateAnimalMicrochipConvertor: TCreateConverter<
  TUpdateAnimalMicrochipDateUI,
  TUpdateAnimalMicrochipDateDB
> = {
  toDb: (data) => {
    return {
      animalId: data.id,
      microchip: data.value,
      tenantResidencyId: data.tenantResidencyId,
    };
  },
};

export const updateAnimalReproductiveConvertor: TCreateConverter<
  TUpdateAnimalReproductiveUI,
  TUpdateAnimalReproductiveDB
> = {
  toDb: (data) => {
    return {
      animalId: data.id,
      reproductive: data.value === 'Yes',
      tenantResidencyId: data.tenantResidencyId,
    };
  },
};

export const updateAnimalHouseTrainedConvertor: TCreateConverter<
  TUpdateAnimalHouseTrainedUI,
  TUpdateAnimalHouseTrainedDB
> = {
  toDb: (data) => {
    return {
      animalId: data.id,
      houseTrained: data.value !== 'Yes',
      tenantResidencyId: data.tenantResidencyId,
    };
  },
};

export const updateAnimalGenderConvertor: TCreateConverter<TUpdateAnimalGenderUI, TUpdateAnimalGenderDB> = {
  toDb: (data) => {
    return {
      animalId: data.id,
      gender: genderEnumConvertor.toDb(data.value),
      tenantResidencyId: data.tenantResidencyId,
    };
  },
};

export const updateAnimalBreedConvertor: TCreateConverter<TUpdateAnimalBreedUI, TUpdateAnimalBreedDB> = {
  toDb: (data) => {
    return {
      animalId: data.id,
      animalBreed: data.breed.__isNew__ ? { value: data.breed.value } : { id: data.breed.value },
      tenantResidencyId: data.tenantResidencyId,
    };
  },
};

export const updateAnimalAggressiveBehaviorConvertor: TCreateConverter<
  TUpdateAnimalAggressiveBehaviorUI,
  TUpdateAnimalAggressiveBehaviorDB
> = {
  toDb: (data) => {
    return {
      animalId: data.id,
      aggressiveBehavior: data.aggressiveBehavior,
      tenantResidencyId: data.tenantResidencyId,
    };
  },
};

export const updateAnimalTrainedForConvertor: TCreateConverter<TUpdateAnimalTrainedForUI, TUpdateAnimalTrainedForDB> = {
  toDb: (data) => {
    return {
      animalId: data.id,
      trainedFor: data.trainedFor,
      tenantResidencyId: data.tenantResidencyId,
    };
  },
};

export const updateAnimalColorsConvertor: TCreateConverter<TUpdateAnimalColorsUI, TUpdateAnimalColorsDB> = {
  toDb: (data) => {
    return {
      animalId: data.id,
      primaryColor: animalColorConvertor.toDb(data.primaryColor as TAnimalColorsUI),
      secondaryColor1: data.secondaryColor1 && data.secondaryColor1.value,
      secondaryColor2: data.secondaryColor2 && data.secondaryColor2.value,
      tenantResidencyId: data.tenantResidencyId,
    };
  },
};

export const updateAnimalProfilePictureConvertor: TCreateConverter<
  TUpdateAnimalProfilePictureUI,
  TUpdateAnimalProfilePictureDB
> = {
  toDb: (data) => {
    return {
      animalId: data.id,
      profilePicture: data.profilePicture,
      tenantResidencyId: data.tenantResidencyId,
    };
  },
};

export const createVetRecordConvertor: TCreateConverter<TAnimalVetRecordCreateUi, TAnimalVetRecordCreate> = {
  toDb: (data) => {
    return {
      animalId: data.animalId,
      records: data.records,
      files: data.files,
      docName: data.files[0].name,
      tenantResidencyId: data.tenantResidencyId,
    };
  },
};

export const vetRecordUpdateConverter: TCreateConverter<TVaccinationRecordUI, TNewVaccinationRecordDB> = {
  toDb: (data) => {
    return {
      type: vaccinationData.includes(data.type.value as string)
        ? {
            predefined: EVetRecordTypeEnumDB[data.type.value as keyof typeof EVetRecordTypeEnumDB],
          }
        : { custom: data.type.value },
      expiration: data.expiration?.date
        ? {
            expired: data.expiration.expired,
            expirable: data.expiration.expirable,
            ...(data.expiration.expirable && {
              date: moment(data.expiration.date).format('YYYY-MM-DDTHH:mm:ss'),
            }),
          }
        : null,
      ...(!data.id?.includes('new') && { id: data.id }),
    };
  },
};

export const updateVaccinationRecordsConverter: TCreateConverter<TVetRecordDocumentsUI, TVetRecordDocumentsDB> = {
  toDb(data) {
    return {
      animalId: data.animalId,
      vetRecordId: data.vetRecordId,
      newFiles: data.newFiles,
      removedDocumentsId: data.removedDocumentsId,
      removedRecordIds: data.removedRecordIds,
      newRecords: (data.newRecords || []).map((record) => vetRecordUpdateConverter.toDb(record)),
      changedRecords: (data.changedRecords || []).map((record) => vetRecordUpdateConverter.toDb(record)),
      tenantResidencyId: data.tenantResidencyId,
    };
  },
};

export const deleteVetRecordConvertor: TDeleteConverter<TVetRecordDeleteUI, TVetRecordDeleteDB> = {
  toDb: (data) => ({
    animalId: data.animalId,
    vetRecordId: data.vetRecordId,
    tenantResidencyId: data.tenantResidencyId,
  }),
};

export const residentAnimalScreening: TDeleteConverter<TResidentAnimalScreeningUI, TResidentAnimalScreeningDB> = {
  toDb: (data) => ({
    tenantResidencyId: data.tenantResidencyId,
    animalResidencyId: data.animalResidencyId,
    needToScreen: data.needToScreen,
  }),
};
export const conditionalMovedInApproveConverter: TCreateConverter<
  TConditionalMovedInApprovedUI,
  TConditionalMovedInApprovedDB
> = {
  toDb: (data) => ({
    tenantResidencyId: data.tenantResidencyId,
    animalResidencyId: data.animalResidencyId,
  }),
};

export const downloadAnimalDocumnetsParamsConverter: TCreateConverter<
  TDownloadAnimalDocumnetsUI,
  TDownloadAnimalDocumnetsDB
> = {
  toDb: (data) => {
    const hpDocumentPacketIds: TDownloadAnimalDocumnetsDB['hpDocumentPacketIds'] = [];
    const saDocumentPacketIds: TDownloadAnimalDocumnetsDB['saDocumentPacketIds'] = [];
    const vetRecordIds: TDownloadAnimalDocumnetsDB['vetRecordIds'] = [];

    data.documnets.forEach((doc) => {
      if (doc.type === 'hpDocumentPacket') {
        hpDocumentPacketIds.push(doc.id);
      } else if (doc.type === 'saDocumentPacket') {
        saDocumentPacketIds.push(doc.id);
      } else if (doc.type === 'vetRecord') {
        vetRecordIds.push(doc.id);
      }
    });

    return {
      animalId: data.animalId,
      tenantResidencyId: data.tenantResidencyId,
      hpDocumentPacketIds,
      saDocumentPacketIds,
      vetRecordIds,
    };
  },
};
