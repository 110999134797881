import { defaultDateFormat, formattedDate, formattedDateTimezone } from 'antd/helpers/utils';
import { b64toBlob } from 'antd/api/sevices/fileServices';
import {
  EMethodDB,
  TAnimalApplicantDetail,
  TAnimalApplicantDetailUi,
  TAnimalResidenciesDetailsDB,
  TAnimalResidenciesDetailsUI,
  TApplicantAnimalScreeningDB,
  TApplicantAnimalScreeningUI,
  TApplicationDetailDB,
  TApplicationDetailsDB,
  TApplicationDetailsUI,
  TApplicationDetailUI,
  TAppMethodDetailsDB,
  TAppMethodDetailsUI,
  TApproveApplicationDB,
  TApproveApplicationFormProps,
  TApproveApplicationFormPropsUI,
  TApproveApplicationUI,
  TApprovePassedMoveInApplicationDB,
  TApprovePassedMoveInApplicationUI,
  TFollowUpItemDB,
  TFollowUpItemUI,
  TStatusChangeHistoryDB,
  TStatusChangeHistoryUI,
  TTenantResidenciesDB,
  TTenantResidenciesDetailsDB,
  TTenantResidenciesDetailsUI,
  TVerificationMessageDB,
  TVerificationMessageUi,
} from '../types/application.types';
import { TCreateConverter, TFetchConverter } from '../types/converter.types';
import {
  animalBasicInfoConvertor,
  animalOwnerConvertor,
  animalTagsConverter,
  animalVetRecordConvertor,
  applicationSADocPacketConvertor,
  dataOverviewConvertor,
  documentFilesConvertor,
} from './animals.converter';
import {
  applicationPolicyConvertor,
  applicationPropertyConvertor,
  applicationResidentConvertor,
  applicationUnitConvertor,
  methodsConvertor,
} from './applicationGeneral.converters';
import { getHPDocPacketsConvertor } from './docPacket.convertors';
import { statusChangeInfoConverter } from './general.converters';
import { animalResidencyStatusConverter } from './residence.converters';
import { EConversionReasonDB, EConversionReasonUI, hudGuidelinesLink } from '../datas/genericData';
import { EPictureOverviewStatusDB, EPictureOverviewStatusUI, EPolicyOverviewStatusDB } from '../types/animals.types';

export const applicationMethodDetailsConvertor: TFetchConverter<TAppMethodDetailsUI, TAppMethodDetailsDB> = {
  fromDb: (data) => ({
    leaseToDate: data.leaseToDate || null,
    moveInDate: data.moveInDate || null,
    property: applicationPropertyConvertor.fromDb({
      propertyId: data.propertyId,
      propertyName: data.propertyName,
    }),
    policy: applicationPolicyConvertor.fromDb({
      policyId: data.policyId,
      policyName: data.policyName,
    }),
    unit: applicationUnitConvertor.fromDb({
      unitId: data.unitId,
      unitNumber: data.unitNumber,
    }),
  }),
};

export const applicationAnimalResidenciesConverter: TFetchConverter<
  TAnimalResidenciesDetailsUI,
  TAnimalResidenciesDetailsDB
> = {
  fromDb: (data) => {
    return {
      id: data.id,
      animalName: data.animalName,
      animalProfilePicture: data.animalProfilePicture,
      animalType: data.animalType,
      animalWeight: data.animalWeight,
      animalSpecie: data.animalSpecie,
    };
  },
};

export const applicationResidenciesConverter: TFetchConverter<
  TTenantResidenciesDetailsUI,
  TTenantResidenciesDetailsDB
> = {
  fromDb: (data) => {
    return {
      id: data.id,
      unitNumber: data.unitNumber,
      propertyName: data.propertyName,
      animalResidencies: data.animalResidencies.map((item) => applicationAnimalResidenciesConverter.fromDb(item)),
    };
  },
};

export const applicationDetailConverter: TFetchConverter<TApplicationDetailUI, TApplicationDetailDB> = {
  fromDb: (data) => {
    const residencyData = data as TTenantResidenciesDB;
    const appMethodDetailData = data as TAppMethodDetailsDB;
    return {
      ...((residencyData.residencies || [])?.length && {
        residencies: residencyData.residencies.map((item) => applicationResidenciesConverter.fromDb(item)),
      }),
      ...(!(residencyData.residencies || [])?.length && applicationMethodDetailsConvertor.fromDb(appMethodDetailData)),
    };
  },
};

export const applicationDetailsConvertor: TFetchConverter<TApplicationDetailsUI, TApplicationDetailsDB> = {
  fromDb: (data) => ({
    resident: applicationResidentConvertor.fromDb({
      residentPhone: data.residentPhone,
      residentName: data.residentName,
      residentEmail: data.residentEmail,
      residentProfilePicture: data.residentProfilePicture,
    }),
    method: methodsConvertor.fromDb(data.method),
    details: data.method === EMethodDB.SIMPLE ? null : applicationDetailConverter.fromDb(data.details[data.method]),
  }),
};

export const statusChangeHistoryConverter: TFetchConverter<TStatusChangeHistoryUI, TStatusChangeHistoryDB> = {
  fromDb(data) {
    return {
      from: data.from ? animalResidencyStatusConverter.fromDb(data.from) : null,
      to: animalResidencyStatusConverter.fromDb(data.to),
      reason: data.reason?.replace(hudGuidelinesLink, 'document'),
      by: data.by || 'N/A',
      date: formattedDateTimezone(data.date, 'MM/DD/YYYY hh:mm A'),
    };
  },
};
export const animalFollowUpItemConvertor: TFetchConverter<TFollowUpItemUI, TFollowUpItemDB> = {
  fromDb: (data) => {
    return {
      authorName: data.isAdmin ? 'OPP Team' : data.authorName,
      createdAt: formattedDate(`${data.createdAt}Z`, `${defaultDateFormat} HH:mm A`),
      description: data.description,
      isCompleteItem: data.isCompleteItem,
      isAdmin: data.isAdmin,
    };
  },
};

export const animalConversionReason: TFetchConverter<EConversionReasonUI, EConversionReasonDB> = {
  fromDb: (data) => {
    const reasons: {
      [key in EConversionReasonDB]: EConversionReasonUI;
    } = {
      [EConversionReasonDB.NOT_RECEIVED_DOCUMENTATION]: EConversionReasonUI.NOT_RECEIVED_DOCUMENTATION,
      [EConversionReasonDB.NOT_REQUIRED_TO_ALLEVIATE]: EConversionReasonUI.NOT_REQUIRED_TO_ALLEVIATE,
      [EConversionReasonDB.SEVERAL_ATTEMPTS_TO_VERIFY]: EConversionReasonUI.SEVERAL_ATTEMPTS_TO_VERIFY,
      [EConversionReasonDB.WAS_NOT_PROVIDED_BY_ITSELF]: EConversionReasonUI.WAS_NOT_PROVIDED_BY_ITSELF,
      [EConversionReasonDB.NOT_REQUIRED_FOR_DISABILITY]: EConversionReasonUI.NOT_REQUIRED_FOR_DISABILITY,
    };
    return reasons[data];
  },
};

export const getApplicantAnimalDetailsConverter: TFetchConverter<TAnimalApplicantDetailUi, TAnimalApplicantDetail> = {
  fromDb: (data) => {
    const isPredefined = Object.values(EConversionReasonDB).includes(data.toPetConvertedReason as EConversionReasonDB);
    return {
      trainedFor: data.trainedFor,
      isRequiredBecauseOfDisability: data.isRequiredBecauseOfDisability,
      hasObservableDisability: data.hasObservableDisability,
      animalMoveInDate: formattedDate(data.animalMoveInDate),
      animal: animalBasicInfoConvertor.fromDb(data.animal),
      canRetractRejection: data.canRetractRejection,
      hpDocPackets: (data.hpDocPackets || []).map((packet) => ({
        ...getHPDocPacketsConvertor.fromDb(packet),
      })),
      ownerInfo: animalOwnerConvertor.fromDb(data.ownerInfo),
      saDocuments: (data.saDocuments || []).map((item) => applicationSADocPacketConvertor.fromDb(item)),
      residencyStatus: animalResidencyStatusConverter.fromDb(data.residencyStatus),
      residencyStatusChangeInfo: data.residencyStatusChangeInfo
        ? statusChangeInfoConverter.fromDb(data.residencyStatusChangeInfo)
        : null,
      vetRecords: (data.vetRecords || []).map((item) => animalVetRecordConvertor.fromDb(item)),
      dataOverview: dataOverviewConvertor.fromDb({
        ...data.dataOverview,
        ...(!data.ownerInfo.isSigned && {
          isPolicySigned: EPolicyOverviewStatusDB.MISSING,
        }),
      }),
      statusChangeHistory: data.statusChangeHistory.map((item) => statusChangeHistoryConverter.fromDb(item)).reverse(),
      isToPetConverted: data.isToPetConverted,
      policyContent: data.policyContent ? b64toBlob(data.policyContent, 'text/html') : null,
      followUpItems: (data.followUpItems || []).map((item) => animalFollowUpItemConvertor.fromDb(item)),
      discoveredTagReason: data.discoveredTagReason,
      tags: (data.tags || []).map((tag) => animalTagsConverter.fromDb(tag)),
      statusUpdateLocked: data.statusUpdateLocked,
      isMoveInDatePassed: data.isMoveInDatePassed,
      isScreeningEnabled: data.isScreeningEnabled,
      toPetConvertedReason: isPredefined
        ? animalConversionReason.fromDb(data.toPetConvertedReason as EConversionReasonDB)
        : data.toPetConvertedReason,
      missingNameReason: data.missingNameReason,
      missingTypeReason: data.missingTypeReason,
      missingMoveInDateReason: data.missingMoveInDateReason,
    };
  },
};

export const residencyDetailConverter: TFetchConverter<TApproveApplicationFormProps, TApproveApplicationFormPropsUI> = {
  fromDb: (data) => ({
    propertyId: data.property.value,
    unitNumber: data.unit.label,
    moveInDate: data.moveInDate,
    leaseToDate: data.leaseToDate,
  }),
};

export const approveApplicationConverter: TCreateConverter<TApproveApplicationUI, TApproveApplicationDB> = {
  toDb: (data) => {
    return {
      applicationId: data.applicationId,
      ...(data.residenceId && { residenceId: data.residenceId }),
      ...(data.residencyDetails && {
        residencyDetails: residencyDetailConverter.fromDb(data.residencyDetails),
      }),
      ...(data.conditionalApproveDetails && {
        conditionalApproveDetails: {
          dueDate: data.conditionalApproveDetails.dueToDate,
          reason: data.conditionalApproveDetails.reason,
        },
      }),
    };
  },
};

export const applicationVerificationMessageConvertor: TFetchConverter<TVerificationMessageUi, TVerificationMessageDB> =
  {
    fromDb: (data) => ({
      message: data.result.length ? data.result[0].reason : undefined,
    }),
  };

export const approvePassedMoveInApplicationConvertor: TFetchConverter<
  TApprovePassedMoveInApplicationUI,
  TApprovePassedMoveInApplicationDB
> = {
  fromDb: (data) => ({
    isResidencyCreated: data.residencyCreated,
    animalResidencyId: data.animalResidencyId,
  }),
};

export const applicationAnimalScreeningConvertor: TCreateConverter<
  TApplicantAnimalScreeningUI,
  TApplicantAnimalScreeningDB
> = {
  toDb: (data) => ({
    applicationId: data.applicationId,
    needToScreen: data.needToScreen,
  }),
};
