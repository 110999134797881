import { animalsTypeCounter, formattedDate } from 'antd/helpers/utils';
import { TFetchConverter } from '../types/converter.types';
import {
  TRoommateAnimalDB,
  TRoommateAnimalUI,
  TRoommateResidentDB,
  TRoommateResidentUI,
  TRoommatesDB,
  TRoommatesUI,
} from '../types/roommates.types';
import {
  aggressiveBehaviorConverter,
  animalStatusConverter,
  animalTypeConverter,
  animalVetRecordConvertor,
  animalWeightConverter,
  applicationSADocPacketConvertor,
  dataOverviewConvertor,
  dnaInfoConverter,
} from './animals.converter';
import { verificationStatusConverter, genderEnumConvertor, reproductiveConvertor } from './general.converters';
import { residenceStatusesConverter } from './residence.converters';
import { getHPDocPacketsConvertor } from './docPacket.convertors';

export const roommateAnimalConverter: TFetchConverter<TRoommateAnimalUI, TRoommateAnimalDB> = {
  fromDb: (data) => {
    return {
      id: data.id,
      name: data.name,
      profilePicture: data.profilePicture,
      status: animalStatusConverter.fromDb(data.status),
      type: animalTypeConverter.fromDb(data.type),
      birthDate: data.birthDate ? formattedDate(`${data.birthDate}Z`, 'MM/DD/YYYY') : null,
      deleted: data.deleted,
      dnaFeatureEnabled: data.dnaFeatureEnabled,
      dnaInfo: data.dnaInfo ? dnaInfoConverter.fromDb(data.dnaInfo) : null,
      breed: data.breed,
      species: data.species,
      primaryColor: data.primaryColor,
      weight: data.weight ? animalWeightConverter.fromDb(data.weight) : null,
      aggressiveBehavior: data.aggressiveBehavior && aggressiveBehaviorConverter.fromDb(data.aggressiveBehavior),
      isHouseTrained: data.isHouseTrained,
      reproduction: data.isReproductive !== null ? reproductiveConvertor.fromDb(data.gender) : null,
      isToPetConverted: data.isToPetConverted,
      photoGallery: data.photoGallery,
      policyContent: data.policyContent,
      secondaryColor1: data.secondaryColor1,
      secondaryColor2: data.secondaryColor2,
      saDocuments: (data.saDocuments || []).map((item) => {
        item.modifiable = false; // hardcoded for edit disable
        return applicationSADocPacketConvertor.fromDb(item);
      }),
      hpDocPackets: (data.hpDocPackets || []).map((packet) => {
        packet.modifiable = false; // hardcoded for edit disable
        return {
          ...getHPDocPacketsConvertor.fromDb(packet),
        };
      }),
      verificationStatus: verificationStatusConverter.fromDb(data.verificationStatus),
      vetRecords: (data.vetRecords || []).map((item) => {
        item.locked = true; // hardcoded for edit disable
        return animalVetRecordConvertor.fromDb(item);
      }),
      gender: genderEnumConvertor.fromDb(data.gender),
      dataOverview: dataOverviewConvertor.fromDb(data.dataOverview),
      isRequiredBecauseOfDisability: data.isRequiredBecauseOfDisability,
      trainedFor: data.trainedFor,
      microchip: data.microchip,
      animalApplicationId: data.animalApplicationId,
      animalResidencyId: data.animalResidencyId,
    };
  },
};

export const roommatesResidentConverter: TFetchConverter<TRoommateResidentUI, TRoommateResidentDB> = {
  fromDb: (data) => {
    return {
      email: data.email,
      hasAnimal: data.hasAnimal === null ? 'N/A' : data.hasAnimal ? 'Yes' : 'No',
      name: data.name,
      phone: data.phone,
      profilePicture: data.profilePicture,
      residentId: data.residentId,
      roommateHasAnimal: data.roommateHasAnimal === null ? 'N/A' : data.roommateHasAnimal ? 'Yes' : 'No',
      status: residenceStatusesConverter.fromDb(data.status),
      animals: data.animals.map((animal) => roommateAnimalConverter.fromDb(animal)),
      animalsCount: animalsTypeCounter(data.animals),
      tenantResidencyId: data.tenantResidencyId,
    };
  },
};

export const roommatesConverter: TFetchConverter<TRoommatesUI, TRoommatesDB> = {
  fromDb: (data) => ({
    leaseToDate: data.leaseToDate ? formattedDate(data.leaseToDate) : '-',
    moveInDate: data.moveInDate ? formattedDate(data.moveInDate) : '-',
    propertyName: data.propertyName,
    unitNumber: data.unitNumber,
    resident: roommatesResidentConverter.fromDb(data.resident),
    roommates: data.roommates.map((roommate) => roommatesResidentConverter.fromDb(roommate)),
  }),
};
